import Notes from "../Notes"

const privateRoutes = [
    {
        path: "/notes",
        element: <Notes />,
    },
];

export default privateRoutes;
